import LogInFormProvider from '@jetshop/ui/Auth/LogInFormProvider';
import t from 'format-message';
import React from 'react';
import { styled } from 'linaria/react';
import { css, cx } from 'linaria';
import { Link } from 'react-router-dom';
import { GlobalError } from '../Forms/GlobalError';
import Input from '../Forms/Input';
import MaxWidth from '../Layout/MaxWidth';
import TrendButton from '../ui/Button';
import { activeSegment, smallSection } from './UI/Form';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { Intl } from '@jetshop/intl';
import Head from '@jetshop/core/components/Head';

import { theme } from '../Theme';

const StyledTrendButton = styled(TrendButton)`
  align-self: center;
  font-family: 'Barlow';
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 16.8px;
  letter-spacing: 0.5px;
  color: white;
  background-color: #6cc400;

  :hover {
    background-color: #6cc400;
  }

  /* &.lightgreen {
    background-color: #c1d1ad; // Light green for the empty state
  }

  &.darkgreen {
    background-color: #6cc400; // Dark green for the filled state
  } */
`;

const actionWrapper = css`
  border: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > * {
    flex: 1 1 calc(50% - 1em);
  }
`;

const TextLink2 = styled('a')`
  font-family: 'Barlow';
  color: #6cc400;
  text-align: right;
  font-size: 1rem;
  line-height: 23.12px;
  font-weight: 400;
  text-decoration: underline;
  margin-bottom: 30px;
  letter-spacing: 1px;

  :hover {
    opacity: 0.8;
  }
  &.signup-link {
    margin-left: auto;
    color: #1a202d;
    text-decoration: none;
  }

  .span {
    color: #6cc400;
    text-decoration: underline;
  }
`;

const TextLink = styled(Link)`
  font-family: 'Barlow';
  color: #6cc400;
  text-align: right;
  font-size: 1rem;
  line-height: 23.12px;
  font-weight: 400;
  text-decoration: underline;
  margin-bottom: 30px;
  letter-spacing: 1px;

  :hover {
    opacity: 0.8;
  }
  &.signup-link {
    margin-left: auto;
    color: #1a202d;
    text-decoration: none;
  }

  .span {
    color: #6cc400;
    text-decoration: underline;
  }
`;

const LogInPageMaxWidth = styled(MaxWidth)`
  align-items: center;
  justify-content: flex-start;
  margin-top: 2rem;
`;

const title = css`
  font-family: 'Barlow';
  font-size: 2.625rem;
  font-weight: 800;
  color: #1a202d;

  ${theme.below.sm} {
    font-size: 2rem;
  }
`;

const paragraph = css`
  font-family: 'Barlow';
  font-size: 1rem;
  font-weight: 400;
  color: #1a202d;
  line-height: 22.4px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const signupContainer = css`
  margin-top: 30px;
  margin-bottom: 30px;
`;

const link = css`
  margin-top: 10px;
`;

function LogInPage() {
  const { routes } = useShopConfig();
  return (
    <LogInPageMaxWidth>
      <Intl>{t => <Head data={{ title: t('Login') }} />}</Intl>
      <p className={title}> {t('Login')}</p>
      <p className={paragraph}>
        {t('Write your email address and password to log in to your account')}
      </p>
      <LogInFormProvider redirect="my-pages">
        {({ globalError, isSubmitting, isValid }) => (
          <section className={cx(smallSection, activeSegment)}>
            <header
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '0.75rem'
              }}
            ></header>
            <Input type="email" name="email" label={t('E-mail address')} />
            <Input
              type="password"
              name="password"
              autoComplete="off"
              label={t('Password')}
            />
            {globalError && (
              <GlobalError style={{ marginBottom: '2em' }}>
                {globalError}
              </GlobalError>
            )}
            <TextLink to={routes.forgotPassword.path}>
              {t('Forgot password')}
            </TextLink>
            <section className={actionWrapper}>
              <StyledTrendButton
                type="submit"
                disabled={!isValid}
                loading={isSubmitting}
                loadingText={t('Hold on a moment...')}
              >
                {t('Log in')}
              </StyledTrendButton>
            </section>
            <section>
              <div className={signupContainer}>
                <div>
                  <TextLink to={routes.signup.path} className="signup-link">
                    {t('Don’t have an account')}
                    <span className="span"> {t('Create one here')}</span>
                  </TextLink>
                </div>
                <div className={link}>
                  <TextLink2
                    href="https://garage.recopart.se/allabildelarverkstad/#/login"
                    className="signup-link"
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    {t('Workshop customer')}?{' '}
                    <span className="span">{t('Log in here')}</span>
                  </TextLink2>
                </div>
              </div>
            </section>
          </section>
        )}
      </LogInFormProvider>
    </LogInPageMaxWidth>
  );
}

export default LogInPage;
