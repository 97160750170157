import { css } from 'linaria';
import { theme } from '../../Theme';

export const smallSection = css`
  display: flex;
  flex-direction: column;
  padding: ${theme.space[1]};
  width: 30rem;
  max-width: 100%;
  margin-bottom: 40px;
  label {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    font-family: 'Barlow';
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 26.01px;
    letter-spacing: 1px;
    color: #1a202d;
  }

  input {
    font-family: 'Barlow';
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 19.6px;
    letter-spacing: 1px;
    color: #1a202d;
  }

  ${theme.below.sm} {
    width: 100%;
  }
`;

export const activeSegment = css`
  position: relative;
`;
